@use "sass:math";
@import "../../../styles/2-tools/tools.rem.scss";
@import "../../../styles/2-tools/tools.media-query.scss";
@import "../../../styles/2-tools/tools.color-square.scss";

.HeaderCard {
	display: flex;
	width: 100%;
	height: 100%;
	max-width: var(--width-md);
	margin: var(--spacing--lg) auto 0;

	&___Left {
		flex-direction: row-reverse;
	}

	@include mq("md", max) {
		min-height: 0;
		flex-direction: column-reverse;
	}

	&___noTopMargin {
		margin-top: 0;
	}
}

.HeaderCard_media {
	position: relative;
	width: 50%;
	background-color: var(--color-white);
	@include mq("md", max) {
		width: auto;
	}

	&___HideImage {
		@include mq("md", max) {
			display: none;
		}
	}
	&___16-9 {
		aspect-ratio: 16/9;
	}
	&___1-1 {
		aspect-ratio: 1/1;
	}

	@media print {
		display: none;
	}
}

.HeaderCard_picture {
	position: absolute;
	left: 0;
	top: 0;
}

.HeaderCard_content {
	display: flex;
	flex-flow: column;
	width: auto;
	flex: 1;
	padding: var(--spacing--lg);

	@include color-square(currentColor);

	.HeaderCard___ThemeWhite & {
		@include color-square(var(--color-theme-primary));
	}

	&___withInfoRight {
		@include mq("md") {
			flex-flow: row;
			justify-content: space-between;
		}
	}
}

.HeaderCard_infoRight {
	font-size: var(--font-size--xs);
	@include mq("md") {
		min-width: 200px;
	}
}

.HeaderCard_infoRightText {
	padding-bottom: var(--spacing--sm);
}

.HeaderCard_heading {
	margin-bottom: var(--spacing--sm);
}

.HeaderCard_teaser {
	font-size: var(--font-size--xl);
	margin-bottom: var(--spacing--sm);
}
